import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shoulder Press 8-8-8\\@75% 1RM`}</p>
    <p>{`DB Rows 8-8-8\\@75% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`10-HSPU’s`}</p>
    <p>{`15-Ring Rows`}</p>
    <p>{`20-GHD Situps`}</p>
    <p><strong parentName="p">{`*`}{`If you’d like a Murph shirt please write your name and shirt/tank
size on the white board soon.  We’ll need to place the order by the
10th.`}</strong></p>
    <p><strong parentName="p">{`*`}{`The next Strength Program will start  Sunday, May 20th.  For more
info email Eric at Fallscitystrength\\@gmail.com.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      